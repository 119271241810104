import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"8","lg":"6","xl":"4"}},[_c(VAlert,{attrs:{"type":"error"},model:{value:(_vm.alert.state),callback:function ($$v) {_vm.$set(_vm.alert, "state", $$v)},expression:"alert.state"}},[_vm._v(" "+_vm._s(_vm.alert.text)+" ")]),_c(VCard,{staticClass:"elevation-10"},[_c(VToolbar,{attrs:{"color":"rfaccent darken-2 text-uppercase","dark":"","dense":""}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-lock")]),_vm._v(" Sign In ")],1),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.login()}}},[_c(VCardText,[_c(VTextField,{attrs:{"label":"Username","autocomplete":"username","outlined":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"label":"Password","type":"password","autocomplete":"current-password","outlined":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"text-center"},[_c(VBtn,{attrs:{"small":"","text":"","to":"/reset-password"}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-help-circle")]),_vm._v(" Forgot Password ")],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"success","to":"/register"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-account-plus")]),_vm._v(" Register ")],1),_c(VSpacer),_c(VBtn,{attrs:{"color":"rfaccent2","dark":"","type":"submit"}},[_vm._v("Sign In "),_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }