<template>
    <v-row justify="center">
        <v-col cols="12" sm="8" md="8" lg="6" xl="4">
            <v-alert v-model="alert.state" type="error">
                {{ alert.text }}
            </v-alert>

            <v-card class="elevation-10">
                <v-toolbar color="rfaccent darken-2 text-uppercase" dark dense>
                    <v-icon small class="mr-1">mdi-lock</v-icon>
                    Sign In
                </v-toolbar>
                <v-form @submit.prevent="login()">
                    <v-card-text>
                        <v-text-field
                            v-model="username"
                            label="Username"
                            autocomplete="username"
                            outlined
                        ></v-text-field>
                        <v-text-field
                            v-model="password"
                            label="Password"
                            type="password"
                            autocomplete="current-password"
                            outlined
                        ></v-text-field>

                        <div class="text-center">
                            <v-btn small text to="/reset-password">
                                <v-icon small class="mr-1">mdi-help-circle</v-icon>
                                Forgot Password
                            </v-btn>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="success" to="/register">
                            <v-icon class="mr-1">mdi-account-plus</v-icon>
                            Register
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="rfaccent2" dark type="submit">Sign In
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import axios from 'axios';
    import config from '../config';

    export default {
        props: ['user'],

        data: () => ({
            loading: false,
            username: null,
            password: null,
            alert: {
                state: false,
                text: null
            }
        }),

        methods: {
            login() {
                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .post(config.API_LOCATION + '/login', {
                        username: this.username,
                        password: this.password
                    }, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.username = null;
                            this.password = null;

                            this.$emit('login', response.data);
                        }
                    })
                    .catch(err => {
                        //@todo do we need to send a logout event to clear state in parent?
                        this.$emit('logout');

                        switch (err.message) {
                            case 'Request failed with status code 400':
                                // this.alert.text = 'The credentials you have provided are incorrect. Please try again.';
                                this.alert.text = err.response.data.message;
                                break;
                            case 'Request failed with status code 502':
                                this.alert.text = 'An error occurred contacting the authentication server. Please try again later.';
                                break;
                            default:
                                this.alert.text = err.message;
                        }
                        this.alert.state = true;
                    });

                return false;
            }
        }
    }
</script>
